.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    color: black;
}

.modalBody {
    padding: 2rem;
    width: 430px;
    background: #fff;
    height: 360px;
    border: 2px solid #D4D4D4; /* Ширина и цвет границ*/
    border-radius: 10px; /* Радиус границ*/
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
}


.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 20px;
    border: 2px solid #D4D4D4; /* Ширина и цвет границ*/
    border-radius: 10px; /* Радиус границ*/
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
    background-color: black;
    color: white;
    margin-top: 50px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.input {
    justify-items: center;
    display: block;
    width: 360px;
    height: 20px;
    border: 2px solid #D4D4D4; /* Ширина и цвет границ*/
    border-radius: 10px; /* Радиус границ*/
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
    background-color: black;
    color: white;
    margin-top: 30px;
    padding: 20px;
    text-align: left;
    font-size: 24px;
}

.header {
    font-size: 24px;
    font-weight: bold;
}
