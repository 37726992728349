.treeCSS,
.treeCSS ul,
.treeCSS li {
    padding: 0px;
    font-family: Roboto;
    list-style: none;
    cursor: default;
}
.treeCSS ul {
    margin: 0 0 0 0;
}
.treeCSS li li {
    position: relative;

    padding-top: 0.2em;
    padding-right: 0;
    padding-left: 1.2em;
    padding-bottom: 0em;
}

.ItemDiv {
    display: block;
    line-height: 30px;
    background-color: #505050;
    padding: 5px;
    margin: 0px;
}

.ItemDiv:hover {
    background-color: #303030;
    border-radius: 5px;
    border-width: 1px;
}
