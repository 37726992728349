.NavBar {
    display: flex;
    flex-flow: row;
    height: 100%;
    justify-content: space-between;
}

.NavLogo {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: left;
    width: 100%;
    margin-left: 10px;
}

.NavLogoText {
    color: #D4D4D4;
    font-size: 48px;
    margin-left: 15px;
    font-weight: bold;
    font-style: italic;

    text-shadow: 0 0 15px #FFFFFF; /* Размер и цвет тени блока*/
}

.NavBtn {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: gray;
}

.NavBtn:hover {
    display: flex;
    flex-flow: column;
    height: 100%;
    background-color: #505050;
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
}

.NavBar a {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    color: white;
}
