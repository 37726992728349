.NavBarIcon {
    width: 25px;
    height: 30px;
    margin: 15px;
    cursor: pointer;
}

.NavBarIconBadged {
    cursor: pointer;
    width: 60px;
}
.NavBarIconBadged:hover {
    /*transform: scale(1.05);*/
}

.badgeBlue {
    position: relative;
    left: 25px;
    top: -60px;
    display: inline-block;
    padding: 2px 2px; /* pixel unit */
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background: blue;
    color: white;
}

.badgeRed {
    position: relative;
    left: 25px;
    top: -60px;
    display: inline-block;
    padding: 2px 2px; /* pixel unit */
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background: red;
    color: white;
}
