.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    color: black;
}

.modalBody {
    padding: 2rem;
    min-width: 100px;
    background: #fff;
    height: 600px;
    border: 2px solid #D4D4D4; /* Ширина и цвет границ*/
    border-radius: 10px; /* Радиус границ*/
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
}

.holder {
    width: auto;
    display: inline-block;
    max-width: 1024px;
    max-height: 550px;
}

.holderImg {
    max-height: 550px;
    height: auto;
}
