.Search {
    display: flex;
    flex-direction: row;
    line-height: 30px;
    width: 100%;
}

.Search input {
    justify-items: center;
    display: block;
    height: 10px;
    width: 100%;
    margin: 10px;
    border: 2px solid #D4D4D4; /* Ширина и цвет границ*/
    border-radius: 10px; /* Радиус границ*/
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
    background-color: black;
    color: white;
    padding: 15px;
    text-align: left;
    font-size: 16px;
}
