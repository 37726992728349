.treeCSS2,
.treeCSS2 ul,
.treeCSS2 li {
    padding: 0px;
    font-family: Roboto;
    list-style: none;
    cursor: default;
}

.treeCSS2 ul {
    margin: 0 0 0 0;
}

.treeCSS2 li li {
    position: relative;

    padding-top: 0.2em;
    padding-right: 0;
    padding-left: 1.2em;
    padding-bottom: 0em;
}

.DownRow {
    display: inline;
    font-size: 14px;
    font-style: italic;
    color: #D4D4D4;
}

.Price {
    display: inline;
    color: orange;
    font-size: 16px;
    font-style: italic;
    margin-left: 10px;
}

.AllRow {
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: #505050;
    line-height: 30px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    color: white;
}

.AllRow:hover {
    background-color: #303030;
    border-radius: 5px;
    border-width: 1px;
}

.LeftPart {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.RightPart {
    display: flex;
    flex-direction: column;
    width: 60px;
}
