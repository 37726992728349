.DownRow {
    display: inline;
    font-size: 14px;
    font-style: italic;
    color: #D4D4D4;
    margin-left: 10px;
    margin-right: 10px;
}

.Price {
    display: inline;
    color: orange;
    font-size: 16px;
    font-style: italic;
}

.Count {
    display: inline;
    color: cyan;
    font-size: 16px;
    font-style: italic;
}

.CatNum {
    display: inline;
    color: #D4D4D4;
    font-weight: bold;
    font-size: 16px;
    font-style: italic;
}

.AllRow {
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: #505050;
    line-height: 30px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    color: white;
}

.AllRow:hover {
    background-color: #303030;
    border-radius: 5px;
    border-width: 1px;
}

.LeftPart {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.RightPart {
    display: flex;
    flex-direction: row;
    width: 100px;
}

.RightBtn {
    width: 25px;
    height: 30px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
