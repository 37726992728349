.BasketContent {
    margin: 10px;
    height: calc(100vh - 60px - 50px);
    overflow: auto;
}

.UpLine {
    margin: 20px;
}

.Summary {
    display: inline;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
}

.SummaryPrice {
    display: inline;
    color: orange;
    font-size: 20px;
    font-style: italic;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px solid #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
    background: #000000;
}

::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: #666666;
}

::-webkit-scrollbar-track:active {
    background: #333333;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

.treeCSS,
.treeCSS ul,
.treeCSS li {
    padding: 0px;
    font-family: Roboto;
    list-style: none;
    cursor: default;
}
.treeCSS ul {
    margin: 0 0 0 0;
}
.treeCSS li li {
    position: relative;

    padding-top: 0.2em;
    padding-right: 0;
    padding-left: 1.2em;
    padding-bottom: 0em;
}

.SendBtn {
    display: inline;
    width: 100px;
    height: 20px;
    border: 2px solid #D4D4D4; /* Ширина и цвет границ*/
    border-radius: 10px; /* Радиус границ*/
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
    background-color: black;
    color: white;
    margin-left: 20px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}
