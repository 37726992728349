.Contacts_modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    color: black;
}

.Contacts_modalBody {
    padding: 2rem;
    width: 770px;
    border-radius: 5px;
    background: #fff;
    height: 470px;
    border: 2px solid #D4D4D4; /* Ширина и цвет границ*/
    border-radius: 10px; /* Радиус границ*/
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
}

.Contacts {
    display: grid;
    grid-template-columns: 300px 400px;
    grid-template-rows: 400px;
    grid-template-areas: "Phones Map";
}

.Phones {
    grid-area: Phones;
}

.Map {
    grid-area: Map;
}

.td {
    padding: 15px;
}

.email {
    text-decoration: none;
}

.contactsButton {
    display: flex;
    flex-direction: row;
    width: 100px;
    height: 50px;
    border: 2px solid #D4D4D4; /* Ширина и цвет границ*/
    border-radius: 10px; /* Радиус границ*/
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
    background-color: black;
    color: white;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
