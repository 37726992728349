.app-wrapper {
    display: grid;
    grid-template-areas:
            "Header"
            "Content"
            "Footer";
    grid-template-columns: 100%;
    grid-template-rows: 60px 1fr 30px;
    height: 100vh;
    min-width: 700px;
}

.App-Content {
    grid-area: Content;
    background-color: grey;
    color: white;
}

.App-Header {
    grid-area: Header;
    background-color: #303030;
    height: 60px;
}

.App-Footer {
    grid-area: Footer;
    background-color: #303030;
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    color: gray;
    font-size: 12px;
}

.App-Footer a {
    text-decoration: none;
    color: white;
    font-size: 12px;
}
