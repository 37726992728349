.CatalogContent {
    display: grid;
    grid-template-areas: "Index Content";
    grid-template-columns: 35% 65%;
    grid-template-rows: 100%;
    height: 100%;
}

.Index {
    grid-area: Index;
    background-color: #505050;
    border: 2px solid #D4D4D4; /* Ширина и цвет границ*/
    box-shadow: 0 0 15px #A9A9A9; /* Размер и цвет тени блока*/
    color: white;
    margin: 0px;
    padding: 10px;
    height: calc(100vh - 60px - 30px);
    overflow: auto;
}

.Content {
    grid-area: Content;
    background-color: #808080;
    padding: 10px;
    height: calc(100vh - 60px - 30px);
    overflow: auto;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px solid #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
    background: #000000;
}

::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: #666666;
}

::-webkit-scrollbar-track:active {
    background: #333333;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
