.nearScheme {
    display: inline;
    font-size: 12px;
    white-space: nowrap;
}

.nearSchemeNum {
    display: inline;
    color: orange;
}

.CatalogItemIcon {
    width: 25px;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.CatalogItemIconDisabled {
    width: 25px;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: black;
    opacity: 50%;
}
